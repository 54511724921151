/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Chalupa Ořešák"} description={"Chalupa nabízí ubytování (nejen) pro rodiny s dětmi, na víkendové nebo letní pobyty. Dále podkrovní sál na jógu či jiné sebe~realizační akce."} seoTitle={"Chalupa Ořešák - Svojšice u Kouřimi"} ogTitle={"Chalupa Ořešák - Svojšice u Kouřimi"} ogDescription={"Chalupa nabízí ubytování (nejen) pro rodiny s dětmi, na víkendové nebo letní pobyty. Dále podkrovní sál na jógu či jiné sebe~realizační akce."} ogImage={"https://cdn.swbpg.com/o/38340/c1737e8a30f1451f95d55815357d8653.jpg"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-kszafw --style3 --full" anim={""} name={"uvod"} animS={"5"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/1786b483ba724355aa4d7d29ee1ccb9a_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/1786b483ba724355aa4d7d29ee1ccb9a_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/1786b483ba724355aa4d7d29ee1ccb9a_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/1786b483ba724355aa4d7d29ee1ccb9a_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/1786b483ba724355aa4d7d29ee1ccb9a_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/1786b483ba724355aa4d7d29ee1ccb9a_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/1786b483ba724355aa4d7d29ee1ccb9a_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/1786b483ba724355aa4d7d29ee1ccb9a_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s5" anim={"7"} animS={"5"}>
              
              <Title className="title-box title-box--center fs--72 mt--08" style={{"maxWidth":1000}} content={"<span style=\"color: white;\">Prostor ~ ubytování ~ workshopy<br></span>"}>
              </Title>

              <Title className="title-box title-box--center fs--86" content={"<font color=\"#fcfcfc\">Chalupa Ořešák</font>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingTop":0,"paddingBottom":12}} name={"zqddsjfz3m"}>
        </Column>


        <Column style={{"paddingTop":38,"marginTop":0,"paddingBottom":0,"marginBottom":0}} name={"informace-1"} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 --center el--1 flex--center" style={{"marginBottom":59,"paddingBottom":5,"paddingLeft":0,"paddingRight":0}} anim={"2"} animS={"2"} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":800,"paddingLeft":0,"marginTop":0}} animS={"3"}>
              
              <Title className="title-box fs--48" content={"Starodávné stavení, které poskytuje pohodlné útočiště."}>
              </Title>

              <Title className="title-box fs--43" style={{"paddingLeft":0,"marginTop":27,"marginBottom":19}} content={"Ubytování ~ letní dovolená ~ víkendové pobyty ~ workshopy ~ teambuildingy ~ rozvojové akce ~ netradiční ubytování ~ skupinky do 12 osob"}>
              </Title>

              <Text className="text-box fs--18" style={{"marginTop":33}} content={"Chalupa se nachází na polosamotě, ve středočeském kraji, <br>v krásné oblasti&nbsp; Polabí, u historického městečka Kouřim.<br>&nbsp;Prostor tvoří trojúhelník mezi Kolínem a Kutnou Horou a je tedy dokonalým místem pro různé výlety. Rovinatá krajina je ideální pro výlety na kole a krajina Posázaví pak pro pobyt v přírodě.&nbsp;&nbsp; <br>Chalupa disponuje sálem pro menší skupiny lidí a lze ho zapůjčit <br>i samostatně, bez noclehu v objektu.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":8}} name={"ky39qdsuis"} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center" style={{"paddingTop":0}}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38340/9c06bf8d3f19436596662f9d376c2453_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/38340/9c06bf8d3f19436596662f9d376c2453_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38340/9c06bf8d3f19436596662f9d376c2453_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38340/9c06bf8d3f19436596662f9d376c2453_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38340/9c06bf8d3f19436596662f9d376c2453_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/38340/9c06bf8d3f19436596662f9d376c2453_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38340/538c549bc07a44849c9fdf4cab32095d_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/38340/538c549bc07a44849c9fdf4cab32095d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38340/538c549bc07a44849c9fdf4cab32095d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38340/538c549bc07a44849c9fdf4cab32095d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38340/538c549bc07a44849c9fdf4cab32095d_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38340/be530e0ab56b4aca9b94c8a0f09177ad_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/38340/be530e0ab56b4aca9b94c8a0f09177ad_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38340/be530e0ab56b4aca9b94c8a0f09177ad_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38340/be530e0ab56b4aca9b94c8a0f09177ad_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38340/be530e0ab56b4aca9b94c8a0f09177ad_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38340/63eb21c6c7614e3299d3dacea2ed4153_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/38340/63eb21c6c7614e3299d3dacea2ed4153_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38340/63eb21c6c7614e3299d3dacea2ed4153_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38340/63eb21c6c7614e3299d3dacea2ed4153_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38340/63eb21c6c7614e3299d3dacea2ed4153_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/38340/63eb21c6c7614e3299d3dacea2ed4153_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"informace-2"}>
        </Column>


        <Column style={{"paddingTop":0,"marginTop":0,"paddingBottom":17}} name={"informace-3"} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--3 flex--center" style={{"maxWidth":"","paddingTop":0,"marginBottom":0,"paddingBottom":14}} anim={"2"} animS={"3"} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":380}}>
              
              <Subtitle className="subtitle-box" style={{"marginTop":0}} content={"Ubytování<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Celkem 9 pohodlných lůžek ve dvou ložnicích <br>+ přistýlka pro 2 osoby.<br>~<br>přízemí: 3 lůžka (dvoulůžko, samostatná postel)<br>podkroví: 6 lůžek (dvoulůžko, 4x samostatná postel, z toho 2x vyvýšená)<br>~<br>V chalupě jsou dvě koupelny a 3x wc.<br>V přízemí koupelna se sprchovým koutem a wc. V podkroví koupelna s vanou a wc, <br>a samostatné wc.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":380}}>
              
              <Subtitle className="subtitle-box" style={{"marginTop":58}} content={"Podkrovní sál<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Prosvětlený&nbsp; sál s hliněnými omítkami, <br>dřevěnou podlahou a výbornou akustikou <br>díky kulatému stropu,<br>s nádherným výhledem na louku a rybník<br>~ <br>kapacita 11 osob (na podložkách, <br>na\nsezení i více)<br>~<br>Sál lze využít i samostatně,\nbez noclehu <br>v objektu, např. na jednodenní akci.<br>~<br>K pobytu pro skupiny lze domluvit stravu.<br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":380}}>
              
              <Subtitle className="subtitle-box" style={{"paddingLeft":8,"paddingRight":0,"marginTop":0,"maxWidth":434,"paddingTop":0}} content={"Užijte si přípravu jídla po vzoru našich babiček"}>
              </Subtitle>

              <Text className="text-box" content={"Srdcem chaloupky jsou kachlová kamna, která už sto let slouží k vaření, sušení a k ohřátí.<br>~<br>Kuchyně je plně vybavena pro vaření <br>a stolování pro cca 11 osob.\n<br>~ <br>K dispozici myčka, pračka, mikrovlnka, plynový sporák s el. troubou, kachlová kamna s litinovým sporákem a troubou. Sady nádobí a hrnců.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":380}}>
              
              <Subtitle className="subtitle-box" content={"Pro děti je to u nás jako malované<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Na naší zahradě a louce děti poznávají přirozenou cestou přírodní děje. Mohou pozorovat kobylky, motýly, šneky.. podílet se na zalévání záhonků, ochutnat sezónní ovoce, zaposlouchat se do zvuků ptáčků..<br>~<br>K dispozici pískoviště s dostatkem hraček, venkovní houpačka. Uvnitř chalupy hrací koutek, knížky a společenské hry.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":380}}>
              
              <Subtitle className="subtitle-box" content={"Louka a rybník<br>"}>
              </Subtitle>

              <Text className="text-box" content={"K domku patří dvě zahrady, dvorek s ořešákem <br>a mírně svažitá louka zakončená rybníkem. <br>~<br>U mola rybníku je kanoe pro romantické plavby. Za rybníkem zapadá slunce a chvíle spočinutí na tomto místě jsou balzámem pro duši.<br>~<br>Rybník není vyloženě koupací, proto v letních měsících jistě uvítáte bazén\n<br>(nadzemní, průměr 4m)."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":380}}>
              
              <Subtitle className="subtitle-box" content={"Zahrada a venkovní posezení<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Horní zahrádka je místo pro spočínutí v klidu, <br>ve stínu stromů, mezi malinami a ostružinami.<br>~<br>Spodní zahrada je oplocená, s ohništěm, grilem a zastřešeným venkovním posezením. <br>Je zde také pískoviště a hamaka.<br>~<br>Ve spodní části louky je k dispozici<br>velké ohniště, které doporučujeme pro větší skupinky lidí.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-11bhnip --parallax pb--60 pt--60" name={"kontakt"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/ef5a1eafc58042e085463ffbc1d8c406_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/ef5a1eafc58042e085463ffbc1d8c406_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/ef5a1eafc58042e085463ffbc1d8c406_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/ef5a1eafc58042e085463ffbc1d8c406_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/ef5a1eafc58042e085463ffbc1d8c406_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/ef5a1eafc58042e085463ffbc1d8c406_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/ef5a1eafc58042e085463ffbc1d8c406_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/ef5a1eafc58042e085463ffbc1d8c406_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2 pb--16 pt--16" anim={"7"} animS={"2"}>
              
              <Title className="title-box" content={"<span style=\"color: white;\">+420 608 859 625 <br>chalupa.oresak@gmail.com<br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" style={{"backgroundColor":"var(--color-supplementary)"}} name={"paticka"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" style={{"maxWidth":""}} anim={""} animS={"2"} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--center" style={{"maxWidth":366,"paddingRight":65}} content={"<span style=\"color: var(--color-dominant);\">&nbsp;~ Blahodárný klid polosamoty ~&nbsp; <br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":205,"paddingBottom":52}}>
            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-dominant);\">Nová Ves III 44 <br>Svojšice okr. Kolín 280 02</span><br><span style=\"color: var(--color-dominant);\">+420 608 859 625<br>chalupa.oresak@gmail.com<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 flex--center" style={{"maxWidth":""}} anim={""} animS={"2"} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--center" style={{"maxWidth":366,"paddingRight":65}} content={"<a href=\"https://www.kudyznudy.cz/?utm_source=kzn&amp;utm_medium=partneri_kzn&amp;utm_campaign=banner\" title=\"Kudyznudy.cz – tipy na výlet\"> <img src=\"https://www.kudyznudy.cz/App_Themes/KzN/CSS/Images/svg/new-logo.svg\" width=\"150\" height=\"33\" border=\"0\" alt=\"Kudyznudy.cz – tipy na výlet\"> </a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":205,"paddingBottom":52}}>
            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--right" style={{"maxWidth":327,"marginTop":0,"marginBottom":0}} content={"<a href=\"https://cdn.swbpg.com/d/38340/zasady-ochrany-os-udaju.pdf\" target=\"_blank\"><i><u style=\"\"><br><span style=\"color: var(--white);\">Zásady ochrany osobních údajů</span><br></u></i></a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}